<template>
    <!-- As of now I didn't find a real way to use the environment var when loading the poster of the video background. Consider using something else than video background for background picture -->
    <router-link class="nav-item nav-link" to="/posts">
        <div class="welcome-wrapper">
            <!--<video-background 
                :src="require(`../assets/background_low_res.mp4`)"
                poster="https://api.altereduniverse.ch/uploads/029_CL_20210123_0007_46cb04383b.jpg"
                style=" "
                overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)" 
            >
                <div class="welcome-statement" style="margin-top:40vh;">
                        <h1>THE UNIVERSE IS</h1>
                        <h1>ALTERED</h1>
                        <img src="" class="altered-logo welcome-statement" alt=".alt_ered">
                </div>
            </video-background>-->
                <div class="welcome-statement"  >
                        <h1>THE UNIVERSE IS</h1>
                        <h1>ALTERED</h1>
                </div>
        </div>
    </router-link>
</template>

<script>
 
export default {
  name: 'Welcome'
}
</script>

<style scoped>
.welcome-wrapper{
    background-image: url(../assets/altered_home_min.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    height: 100vh;
}

.welcome-statement{
    padding-top:40vh;
}

.vue-responsive-videobg .videobg-content{
    display: block;
    justify-content: center;
    align-items: center;
}

.welcome-statement h1{
    font-size: 5vw;
    font-weight:900;
    color:#fdfd00;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.nav-item{
    padding: 0;
    margin: 0;
}

@media screen and (min-width: 800px) {
    .welcome-statement h1{
        font-size: 6.5vw;
    }

    .vue-responsive-videobg{
        height: 90vh;
    }

    .altered-logo{
        width: 35vw;
    }
}

@media screen and (min-width: 700px) and (max-width:799px) {
    .welcome-statement h1{
        font-size: 11vw;
    }
}

@media screen and (max-width: 699px) {
    .welcome-statement h1{
        font-size: 11vw;
    }
}


</style>